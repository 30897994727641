export enum SnackBarDuration {
  SHORT_SNACKBAR_DURATION = 4000,
  LONG_SNACKBAR_DURATION = 8000,
}

export enum SnackBarType {
  Primary = 'primary',
  Warning = 'warning',
  Error = 'Error',
  Info = 'info',
}
