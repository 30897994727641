import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { ButtonSize } from '../button/button-size.enum';
import { ButtonType } from '../button/button-type.enum';
import { ButtonsComponent } from '../button/button.component';
import { IconSize } from '../icon/icon-size.enum';
import { IconType } from '../icon/icon-type.enum';
import { IconsComponent } from '../icon/icon.component';

@Component({
  selector: 'myt-snackbar',
  standalone: true,
  imports: [IconsComponent, ButtonsComponent],
  templateUrl: './snackbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackbarComponent {
  readonly data = inject(MAT_SNACK_BAR_DATA);
  readonly iconSize = IconSize;
  readonly iconType = IconType;
  readonly buttonType = ButtonType;
  readonly buttonSize = ButtonSize;
}
