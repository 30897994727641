import { Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IconType } from '../icon/icon-type.enum';
import { SnackbarComponent } from './snackbar.component';
import { SnackBarDuration } from './snackbar.enum';
import { SnackbarExtras, SnackbarOptions } from './snackbar.interface';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  readonly snackbarService = inject(MatSnackBar);

  readonly iconType = IconType;

  private _open({ message, panelClass, icon, iconType, duration, dismissible }: SnackbarOptions): void {
    const snackBar = this.snackbarService.openFromComponent(SnackbarComponent, {
      panelClass: panelClass,
      duration: duration || SnackBarDuration.LONG_SNACKBAR_DURATION,
      horizontalPosition: 'left',
      data: {
        icon,
        iconType,
        message,
        dismissible: dismissible ?? true,
        close: () => {
          snackBar.dismiss();
        },
      },
    });
  }

  openSuccessfulSnackbar(message: string, { duration, dismissible }: SnackbarExtras = {}): void {
    this._open({ message, panelClass: ['snackbar-success'], icon: 'check_circle', iconType: this.iconType.Primary, duration, dismissible });
  }

  openErrorSnackbar(message: string, { duration, dismissible }: SnackbarExtras = {}): void {
    this._open({ message, panelClass: ['snackbar-error'], icon: 'error', iconType: this.iconType.Error, duration, dismissible });
  }

  openWarningSnackbar(message: string, { duration, dismissible }: SnackbarExtras = {}): void {
    this._open({ message, panelClass: ['snackbar-warning'], icon: 'warning', iconType: this.iconType.Warning, duration, dismissible });
  }

  openInfoSnackbar(message: string, { duration, dismissible }: SnackbarExtras = {}): void {
    this._open({ message, panelClass: ['snackbar-info'], icon: 'info', iconType: this.iconType.Accent, duration, dismissible });
  }
}
