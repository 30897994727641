<div class="flex items-center justify-between">
  <div class="mx-3 flex items-start justify-center">
    <myt-icon [name]="data.icon" [type]="data.iconType" [size]="iconSize.ExtraSmall" />
    <p class="mx-2" [innerHTML]="data.message"></p>
  </div>
  @if (data.dismissible) {
    <div class="mx-1">
      <myt-button [type]="buttonType.Icon" [size]="buttonSize.Medium" (buttonClick)="data.close()">
        <myt-icon [type]="iconType.Black" name="close" [size]="iconSize.ExtraSmall" />
      </myt-button>
    </div>
  }
</div>
